import React from "react";

const SwapIframe = ({ tokenAddress }) => {
  const uniswapUrl = `https://app.uniswap.org/#/swap?outputCurrency=0x2d141D052219ebd5dd39ECefA4D9fbBc9f0A118f&chain=base`;;

  return (
    <div style={{ textAlign: "center", margin: "20px 0" }}>
      <iframe
        src={uniswapUrl}
        title="Uniswap Swap"
        width="100%"
        height="600"
        style={{ border: "none", borderRadius: "10px" }}
      />
    </div>
  );
};

export default SwapIframe;