import React, { useState } from "react";
import "./FaqSection.css";

const FAQSection = () => {
  const faqs = [
    {
      question: "What is CryptoBros?",
      answer: "CryptoBros is a community-driven cryptocurrency designed by the people, for the people. It aims to create a transparent and fair ecosystem for self-custody holders while providing opportunities to earn rewards through innovative tokenomics.",
    },
    {
      question: "Is CryptoBros a currency or a security?",
      answer: "CryptoBros is a decentralized currency, not a security. It’s designed for use as a medium of exchange and operates on transparent, community-driven principles. There’s no centralized entity promising returns—only the ecosystem and its participants.",
    },
    {
      question: "How does CryptoBros reward holders",
      answer: "CryptoBros has built-in passive staking, which means holders earn dividends in USDC simply by holding the token in their wallets. Dividends are distributed fairly and transparently, with the more tokens you hold, the more rewards you receive.",
    },
    {
      question: "What is the purpose of the marketing fund?",
      answer: "Initially, the marketing fund will focus on securing listings on Tier 1 Centralized Exchanges (CEXs) to drive trading volume and attract new participants. Once listed, all marketing fund initiatives will be decided by community vote, ensuring the funds are used to expand the ecosystem and reward holders.",
    },
    {
      question: "What is the buyback and burn strategy?",
      answer: "Profits from liquidity pairs hosted on Tier 1 CEXs are used to buy back and burn CryptoBros tokens. This reduces the total supply, increasing the value of tokens held by self-custody holders and rewarding the community.",
    },
    {
      question: "How does CryptoBros differ from traditional financial systems?",
      answer: "Unlike banks and centralized institutions that manipulate currency and limit access, CryptoBros operates on a decentralized blockchain, ensuring transparency, fairness, and true financial sovereignty for its holders.",
    },
    {
      question: "Why are CEX wallets excluded from dividends?",
      answer: "To prioritize and reward self-custody holders, CEX wallets are excluded from dividend payments. This ensures that the benefits of holding CryptoBros tokens stay within the community, empowering individual holders.",
    },
    {
      question: "Is CryptoBros really trying to mint a trillion dollars a year?",
      answer: "Yes, but with a twist—CryptoBros aims to do so transparently, honestly, and without robbing taxpayers, unlike traditional systems. The goal is to create value and empower the community while demonstrating accountability.",
    },
    {
      question: "How does CryptoBros plan to expand cross-chain?",
      answer: "CryptoBros will begin on low-gas Layer 2 networks like Base, ensuring accessibility and efficiency. As liquidity grows, the token will expand to dominate the Layer 1 space, making CryptoBros a powerhouse in blockchain ecosystems.",
    },
    {
      question: "How can I get involved with CryptoBros?",
      answer: "Getting involved is easy! You can purchase CryptoBros tokens, participate in community votes, and help shape the ecosystem. By holding the token, you’re not just an investor—you’re part of the movement for financial freedom and transparency.",
    },
    {
      question: "Why is the CryptoBros ecosystem called revolutionary?",
      answer: "CryptoBros challenges the traditional financial system by putting power directly in the hands of the people. It promotes self-custody, decentralization, and transparency while rejecting the practices of centralized financial institutions.",
    },
    {
      question: "WTF is with these taxes Bros?",
      answer: "CryptoBros’ fee structure is a carefully balanced ecosystem designed to maximize benefits for the community while ensuring the long-term growth and stability of the token. The Liquidity Fee 3% keeps trading smooth and fair by reducing slippage, ensuring that both small and large trades don’t disrupt the market. The Buyback Fee 2% strategically reduces the token supply, creating scarcity and directly boosting the value of every token held. The Reflection Fee 7% rewards loyal holders with passive income in USDC, turning every trade into an opportunity for community-wide dividends. The Marketing Fee 2% powers efforts to grow the CryptoBros brand, from securing Tier 1 exchange listings to expanding the ecosystem for all holders. Together, these fees make every transaction work harder for the community, ensuring that CryptoBros isn’t just another token—it’s a powerhouse of value creation and sustainability",
    },
    
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <section className="faq-section">
      <h2>Frequently Asked Questions</h2>
      <div className="faq-container">
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <div
              className={`faq-question ${activeIndex === index ? "active" : ""}`}
              onClick={() => toggleFAQ(index)}
            >
              {faq.question}
            </div>
            {activeIndex === index && (
              <div className="faq-answer">{faq.answer}</div>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default FAQSection;