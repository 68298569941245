import React, { useState } from "react";
import SwapIframe from "./SwapIframe"; // Ensure this component is set up for the Uniswap iframe

const PresentationComponent = ({ tokenAddress }) => {
  const heading = "The Meme Coin CryptoBros";

  // List of reasons with their corresponding image URLs
  const reasons = [
    {
      text: `“Brad Sherman says CryptoBros have printed a trillion dollars out of thin air. Sounds like a superpower, doesn’t it? The real question is—are you ready to join the CryptoBros and help us print another trillion dollars out of thin air? The meme economy is waiting for you!”`,
      youtube: "https://www.youtube.com/embed/MamB2vKl4Zc",
    },
    {
      text: `“Let’s set the record straight: CryptoBros don’t print money out of thin air—we mint it with style. Every one of our magic internet coins is transparently accounted for on a decentralized ledger, where anyone can verify its creation, movement, and purpose. Unlike the real magicians at the U.S. Treasury, who conjure up trillions of untrackable dollars with the wave of a wand (or a printer), our system operates with full transparency and zero secret handshakes. Accountability is the name of the game—because if you’re going to create value from the ether, at least let people see how the trick is done!”`,
      image: "/images/ustreasury.png", // Replace with actual path to the generated image
    },
    {
      text: `“Central bankers and mutual funds are like the ‘trusted babysitters’ who raid the fridge and throw a house party while you’re away. Instead of safeguarding your money, they’re out there trading it like a hot potato, snapping up assets and driving prices sky-high. Meanwhile, their wild spending spree debases your currency, leaving you holding a rapidly shrinking dollar as inflation skyrockets. It’s the ultimate plot twist: the very people meant to protect your wealth are the ones quietly inflating it away while they corner the market. Who needs enemies with ‘friends’ like these?”`,
      image: "/images/hedgies.png", // Replace with actual path to the generated image
    },
    {
      text: `“CryptoBros are the everyman’s rebellion—a grassroots army of individuals reclaiming their financial sovereignty one block at a time. Armed with self-custody wallets and the unstoppable force of weaponized autism, they’re taking back the value of their currency from the clutches of bankers and hedge funds. Unlike the suits who gamble away your wealth and inflate your cost of living, CryptoBros are here to decentralize power and restore fairness.

Every transaction, every coin held in self-custody, is a blow to the system that’s been robbing us blind for generations. This isn’t just a movement; it’s a revolution to protect our birthright and secure a future where value is determined by the people—not corporate overlords. CryptoBros aren’t just building a new financial system—they’re reclaiming the dream of true freedom.”`,
      image: "/images/birthright.png", // Replace with actual path to the generated image
    },
    {
      text: `“At the heart of the CryptoBros revolution is a smart contract designed to reward every participant fairly—no suits required. Our contract uses a transparent and decentralized dividend distribution system that ensures every holder gets their rightful share of the spoils.

Dividends are distributed in USDC, directly to the wallets of CryptoBros who choose to partake in this movement. The more you hold, the more you earn—it’s that simple. Unlike the centralized systems that funnel wealth to the top, our mechanism operates on a pro-rata basis, meaning everyone gets their fair slice of the pie, with no shady backroom deals or insider privileges.

By holding CryptoBros tokens, you’re not just supporting the fight for financial sovereignty—you’re actively participating in it. Every transaction fuels the system, redistributing power and wealth back to the people. It’s not just a token; it’s a declaration of independence from the bankers and hedge funds that have long ruled over us.”`,
      image: "/images/defirevo.png", // Replace with actual path to the generated image
    },
    {
      text: `Traditional financial systems are limited by borders, regulations, and inefficiencies. CryptoBros operate in a truly global economy, where anyone, anywhere, can transact instantly and freely, no passport required. The only border is your imagination.`,
      image: "/images/borderless.png", // Replace with actual path to the generated image
    },
    {
      text: `Banks decide who can build, innovate, or even access financial services. With CryptoBros, there’s no gatekeeping—anyone can create, contribute, and participate in a decentralized ecosystem where permission comes from the code, not the suits.`,
      image: "/images/bankgate.png", // Replace with actual path to the generated image
    },
    {
      text: `CryptoBros isn’t just another speculative asset—it’s real currency designed for real people. With its decentralized nature and primary use as a medium of exchange, CryptoBros operates as true money, not an investment contract. That means it doesn’t fall under the purview of the bobos at the SEC. While securities rely on promises from centralized entities, CryptoBros delivers value directly, transparently, and without the need for middlemen. No oversight, no regulations—just a people-powered currency that works the way money was always meant to.`,
      image: "/images/secbears.png", // Replace with actual path to the generated image
    },
    {
      text: `“Liquidity provision is at the core of CryptoBros’ strategy to become a cross-chain powerhouse. Starting on Base and other low-gas Layer 2 networks, CryptoBros is designed to thrive in efficient, scalable ecosystems while ensuring accessibility for everyone. With a strong foundation on L2s, the goal is to expand and dominate the Layer 1 landscape, positioning CryptoBros as a leader in sovereign self-custody blockchain projects. By prioritizing decentralization, scalability, and user empowerment, CryptoBros isn’t just launching a token—it’s spearheading a movement to redefine blockchain sovereignty on every chain.”`,
      image: "/images/l2domination.png", // Replace with actual path to the generated image
    },
    {
      text: `“Okay, imagine you have a magical piggy bank called CryptoBros. When you put your special coins (tokens) into this piggy bank, it doesn’t just sit there—it works for you! Every time someone else buys or sells CryptoBros tokens, a tiny bit of magic happens. Part of that transaction is turned into USDC (a super-stable, digital version of money) and sent to all the piggy banks of people who are holding their tokens.

The best part? You don’t have to do anything—no complicated forms, no button to press. As long as you’re holding your tokens, your piggy bank will keep getting little rewards all the time. And the more tokens you have, the bigger your share of the rewards!

Think of it like getting free candy just for keeping your toys in your toy box. But instead of candy, it’s USDC. And instead of toys, it’s CryptoBros tokens. Everyone gets a fair amount of rewards based on how many tokens they’re holding—no cheating, no special treatment for anyone. It’s simple, it’s fair, and it’s awesome!”`,
      image: "/images/passivestaking.png", // Replace with actual path to the generated image
    },
    {
      text: `Our marketing fund is laser-focused on securing Tier 1 CEX (Centralized Exchange) listings to drive massive trading volume and maximize rewards for our holders. Any profits generated from liquidity pairs hosted on these exchanges will be reinvested into buybacks and token burns, directly increasing the value for self-custody holders. To ensure fairness, CEX wallets are excluded from dividend payments—keeping all rewards exclusively for true CryptoBros who hold their tokens in self-custody. With this strategy, every move benefits the community, ensuring that CryptoBros always come out ahead.`,
      image: "/images/marketingt1.png", // Replace with actual path to the generated image
    },
    {
      text: `In CryptoBros, the power is in your hands! After we’re listed on the biggest Tier 1 exchanges, all decisions about how to use the marketing funds will be made by you—our amazing community. Every single penny will go towards projects and ideas that you vote for. Want to expand the ecosystem? Done. Want more rewards for holders? You got it.

Think of it like a big family deciding how to spend their treasure chest, except everyone gets a say, and it’s all for making CryptoBros even bigger and better. You’re not just a holder; you’re a part of the team shaping the future!”`,
      image: "/images/pepesvoting.png", // Replace with actual path to the generated image
    },
    {
      text: `CryptoBros is a token created by the people, for the people, with a bold new mission inspired by none other than Mr. Sherman himself: to mint a trillion dollars every year. While we may never match the sheer volume of money printed by the U.S. government, we’ll do it transparently, honestly, and without stealing from taxpayers.

So, Mr. Sherman, you may represent the U.S. government, but we represent the people—and we’re done putting up with your nonsense. CryptoBros isn’t just a token; it’s a movement for accountability, fairness, and true financial freedom.`,
      image: "/images/thepepl.png", // Replace with actual path to the generated image
    },
  ];

  const [currentStep, setCurrentStep] = useState(0);
  const [showIframe, setShowIframe] = useState(false);

  const handleNext = () => {
    if (currentStep < reasons.length - 1) {
      setCurrentStep((prev) => prev + 1);
    } else {
      setShowIframe(true);
    }
  };

  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      {!showIframe ? (
        <>
          <h1>{heading}</h1>
          {reasons[currentStep].youtube ? (
            <iframe
              width="560"
              height="315"
              src={reasons[currentStep].youtube}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title={`YouTube Video ${currentStep + 1}`}
              style={{ marginBottom: "20px" }}
            ></iframe>
          ) : (
            <img
              src={reasons[currentStep].image}
              alt={`Slide ${currentStep + 1}`}
              style={{
                maxWidth: "20%", // Adjusts image size to 50% of the container width
                height: "auto",
                marginBottom: "20px",
              }}
            />
          )}
          <p style={{ whiteSpace: "pre-line" }}>{reasons[currentStep].text}</p>
          <button
            onClick={handleNext}
            style={{
              marginTop: "20px",
              padding: "10px 20px",
              background: "#ff416c",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            {currentStep < reasons.length - 1 ? "Would you like to know more?" : "Buy Now"}
          </button>
        </>
      ) : (
        <SwapIframe tokenAddress={tokenAddress} />
      )}
    </div>
  );
};

export default PresentationComponent;