import React, { useState } from "react";
import "./App.css";
import LoadingScreen from "./components/LoadingScreen";
import PresentationComponent from "./components/PresentationComponent"; // Import the PresentationComponent
import FAQSection from "./components/FaqSection";

function App() {
  const [loadingComplete, setLoadingComplete] = useState(false);

  // Replace with your token's contract address
  const tokenAddress = "0x2d141D052219ebd5dd39ECefA4D9fbBc9f0A118f";

  return (
    <div className="App">
      {!loadingComplete ? (
        <LoadingScreen onComplete={() => setLoadingComplete(true)} />
      ) : (
        <>
          <section className="hero starry-background">
  <img
    src="/images/pepeprintingmoney.png"
    alt="CryptoBros Logo"
    className="circular-image"
  />
  <h1>CryptoBros (Sherman)</h1>
  <p>Base Mainnet</p>
  <p>Contract Address: 0x2d141D052219ebd5dd39ECefA4D9fbBc9f0A118f</p>
  <p>The definitive meme coin for CryptoBros who love "making money out of thin air!"</p>

  {/* Active Buttons */}
  <div className="button-group">
    <a
      href="hwww.dextools.io/app/en/base/pair-explorer/0x6322f82e588e85236275878ca24f79998b323d62/"
      target="_blank"
      rel="noopener noreferrer"
      className="active-button"
    >
      Dextools
    </a>
    <a
      href="https://dexscreener.com/"
      target="_blank"
      rel="noopener noreferrer"
      className="active-button"
    >
      DexScreener
    </a>
    <a
      href="https://app.uniswap.org/#/swap?inputCurrency=0x2d141D052219ebd5dd39ECefA4D9fbBc9f0A118f&outputCurrency=ETH"
      target="_blank"
      rel="noopener noreferrer"
      className="active-button"
    >
      Uniswap
    </a>
  </div>

  {/* Greyed Out Buttons */}
  <div className="button-group greyed-out">
    <button disabled>Forbes</button>
    <button disabled>CoinGecko</button>
    <button disabled>CoinMarketCap</button>
    <button disabled>Gate.io</button>
    <button disabled>Kraken</button>
    <button disabled>Binance</button>
    <button disabled>CoinBase</button>
    <button disabled>Kucoin</button>
    <button disabled>BitMart</button>
    <button disabled>MEXC</button>
  </div>
</section>

          {/* Presentation Section */}
          <section className="presentation nebula-background">
            <PresentationComponent tokenAddress={tokenAddress} />
          </section>

          {/* About  */}
          <section className="about starry-background">
            <h2>About CryptoBros (Sherman)</h2>
            <img src="/images/pepebros.png" alt="CryptoBros" />
            <div className="about-text">
              <p>
              Welcome to CryptoBros, the ultimate satirical meme token that takes a tongue-in-cheek jab at the world of cryptocurrency, the financial system, and the absurdity of modern markets. Born from the infamous statements of Congressman Brad Sherman, who famously called crypto enthusiasts “Crypto Bros,” this project embraces the satire while delivering a surprisingly well-thought-out platform.

CryptoBros is more than just a meme—it’s a community-driven experiment designed to highlight the quirks of decentralized finance and the evolving nature of money itself. With a name as bold as its mission, CryptoBros aims to prove that even humor has a place in the future of finance.
              </p>
              <p>
                 Pro-Rata Dividends paid in Fiat tokens, a fixed supply, and an unrivaled sense of humor, CryptoBros
                are the true champions of decentralization. Whether you’re here to laugh,
                hodl, or moon, CryptoBros are ready to make MemeCoins Great Again!
              </p>
            </div>
          </section>

          {/* Tokenomics */}
          <section className="tokenomics nebula-background">
            <h2>Tokenomics</h2>
            <ul>
              <li>
                <strong>Contract Address:</strong>0x2d141D052219ebd5dd39ECefA4D9fbBc9f0A118f
              </li>
              <li>
                <strong>Total Supply:</strong> 1,000,000,000,000,000 $Sherman
              </li>
              <li>
                <strong>Taxes:</strong> 2% for listings, 3% for liq provision, 7% to Holder Dividends, 2% to buyback fund.
              </li>
              <li>
                <strong>Liquidity:</strong> To be locked for 4.2 years
              </li>
              <li>
                <strong>No PreSale:</strong> Initial Liq provided by CryptoBros
              </li>
            </ul>
          </section>

          {/* Roadmap */}
          <section className="roadmap starry-background">
            <h2>Roadmap</h2>
            <ol>
              <li>✅ Launch Token</li>
              <li>🚀 1,000 Holders</li>
              <li>🌕 List on Tier 1 Exchanges</li>
              <li>Social Marketing Faucet</li>
              <li>Get endorsed by Rep. Brad Sherman</li>
            </ol>
          </section>

          {/* Community */}
          <section className="community nebula-background">
            <h2>Join the Community</h2>
            <p>Follow us on our socials and be part of the CryptoBros Movement!</p>
            <ul>
              <li>
                <a href="https://x.com/Shermanonbase" target="_blank" rel="noopener noreferrer">
                  Twitter
                </a>
              </li>
              <li>
                <a href="https://t.me/CryptoBrosToken" target="_blank" rel="noopener noreferrer">
                  Telegram
                </a>
              </li>
            </ul>
          </section>
         {/* FAQ Section */}
         <section className="faq starry-background">
            <FAQSection />
          </section>
         {/* Disclaimer */}
         <section className="disclaimer">
            <p>
              Disclaimer: CryptoBros is a meme-based cryptocurrency project and should not be
              considered financial advice. Investing in cryptocurrency involves significant
              risk, including the possible loss of your entire investment. Always do your
              own research and consult with a financial advisor. CryptoBros is for entertainment
              purposes only.
            </p>
          </section>
         {/* Footer */}
          <footer className="footer">
            <p>
              © 2024 CryptoBros - All Rights Reserved | Powered by Memes and Degeneracy
            </p>
          </footer>
        </>
      )}
    </div>
  );
}

export default App;