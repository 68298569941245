import React, { useState, useEffect } from "react";
import "./LoadingScreen.css";

const LoadingScreen = ({ onComplete }) => {
  const images = [
    { src: "/images/pepebang.png", interval: 1000 },
    { src: "/images/dawnofpepe.png", interval: 1100 },
    { src: "/images/pepebarter.png", interval: 1200 },
    { src: "/images/ledgerpepe.png", interval: 1300 },
    { src: "/images/abacus.png", interval: 1400 },
    { src: "/images/pepegold.png", interval: 1500 },
    { src: "/images/antikythera.png", interval: 1600 },
    { src: "/images/astrolabe.png", interval: 1700 },
    { src: "/images/silkpepes.png", interval: 1800 },
    { src: "/images/pepeprinting.png", interval: 1900 },
    { src: "/images/pepebankor.png", interval: 2000 },
    { src: "/images/pepestonks.png", interval: 2100 },
    { src: "/images/analytics.png", interval: 2200 },
    { src: "/images/pepecomputing.png", interval: 2300 },
    { src: "/images/pepecredit.png", interval: 2400 },
    { src: "/images/onlinepepeing.png", interval: 2500 },
    { src: "/images/pepecrypto.png", interval: 2600 },
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const [fade, setFade] = useState(false);

  useEffect(() => {
    let imageTimeout;

    const showNextImage = () => {
      setFade(true); // Trigger fade-out
      setTimeout(() => {
        setFade(false); // Trigger fade-in
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length); // Switch image
        setProgress((prevProgress) =>
          Math.min(prevProgress + 100 / images.length, 100)
        ); // Increment progress
      }, 250); // Ensure fade-out before image switch
    };

    imageTimeout = setTimeout(showNextImage, images[currentImageIndex].interval);

    if (progress >= 100) {
      clearTimeout(imageTimeout);
      setTimeout(() => {
        onComplete(); // Trigger the completion callback
      }, 500);
    }

    return () => clearTimeout(imageTimeout);
  }, [currentImageIndex, images, progress, onComplete]);

  return (
    <div className="loading-screen">
      <div className="loading-bar">
        <div className="progress" style={{ width: `${progress}%` }}></div>
      </div>
      <div className={`image-container ${fade ? "fade-out" : "fade-in"}`}>
        <img
          src={images[currentImageIndex].src}
          alt={`Loading resource ${currentImageIndex + 1}`}
          className="loading-image"
        />
      </div>
    </div>
  );
};

export default LoadingScreen;